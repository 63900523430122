import { isEmpty } from "ramda";
import React, { ReactNode } from "react";
import useCompanyInfo from "../../hooks/useCompanyInfo/useCompanyInfo";

import { Location } from "../Location/Location";
import { FooterBlock } from "../FooterBlock/FooterBlock";
import { Link } from "../Link/Link";
import { LinkType } from "../Link/Link.types";

export const Footer: React.FC = () => {
  const {
    book,
    contact: { location, phone, gsm, email },
    socials: { facebook, instagram },
  } = useCompanyInfo();

  const renderLink = (title: string, url: string, type: LinkType = LinkType.External): ReactNode => (
    <li className="m-footer-block__list-item">
      <Link title={title} href={url} type={type} cn="m-footer-block__link">
        {title}
      </Link>
    </li>
  );

  return (
    <footer className="o-footer u-wrapper">
      <h2 className="u-hidden">Footer</h2>
      <FooterBlock title="Locatie">
        <Location {...location} cn="m-footer-block__address" />
      </FooterBlock>

      <FooterBlock title="Volg ons">
        <ul className="m-footer-block__list">
          {!isEmpty(facebook) && renderLink(`Facebook`, facebook)}
          {!isEmpty(instagram) && renderLink(`Instagram`, instagram)}
        </ul>
      </FooterBlock>

      <FooterBlock title="Contact">
        <ul className="m-footer-block__list">
          <li className="m-footer-block__list-item">
            <Link
              title="Stuur ons een email"
              href={`mailto:${email}`}
              cn="m-footer-block__link"
              type={LinkType.External}
            >
              {email}
            </Link>
          </li>
          <li className="m-footer-block__list-item">
            <Link title="Bel ons" href={`tel:${phone}`} cn="m-footer-block__link" type={LinkType.External}>
              Tel: {phone}
            </Link>
          </li>
          <li className="m-footer-block__list-item">
            <Link title="Bel ons" href={`tel:${gsm}`} cn="m-footer-block__link" type={LinkType.External}>
              GSM: {gsm}
            </Link>
          </li>
        </ul>
      </FooterBlock>

      <FooterBlock title="Afpsraak">
        <ul className="m-footer-block__list">{!isEmpty(book) && renderLink(`Boek een afspraak`, book)}</ul>
      </FooterBlock>
    </footer>
  );
};
