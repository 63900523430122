import React, { ReactElement } from "react";
import { Link as GatsbyLink } from "gatsby";

import { LinkProps, LinkType } from "./Link.types";

export const Link: React.FC<LinkProps> = ({
  title,
  href,
  type = LinkType.Internal,
  cn,
  activeCn,
  children,
}: LinkProps) => {
  const renderInternalLink = (): ReactElement => (
    <GatsbyLink to={href} title={title} className={cn} activeClassName={activeCn}>
      {children}
    </GatsbyLink>
  );

  const renderExternalLink = (): ReactElement => (
    <a title={title} href={href} target={type} rel="noopener" className={cn}>
      {children}
    </a>
  );

  return type === LinkType.Internal ? renderInternalLink() : renderExternalLink();
};
