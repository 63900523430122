import React from "react";
import cx from "classnames";

import { LocationProps } from "./Location.types";

export const Location: React.FC<LocationProps> = ({ cn, street, number, bus, zip, city }: LocationProps) => (
  <address className={cx(`a-location`, cn)}>
    <p>
      {street} {number} {bus}
    </p>
    <p>{`${zip} ${city}`}</p>
  </address>
);
