import React, { useState, useEffect } from "react";
import cx from "classnames";
import UseAnimations from "react-useanimations";
import menu2 from "react-useanimations/lib/menu2";

import { Logo } from "../Logo/Logo";
import { LogoColor } from "../Logo/Logo.types";
import { Link } from "../Link/Link";
import { LinkType } from "../Link/Link.types";

import { Routes } from "../../types/routes";
import useCompanyInfo from "../../hooks/useCompanyInfo/useCompanyInfo";
import useSiteMetaData from "../../hooks/useSiteMetaData/useSiteMetaData";
import { HamburgerProps, NavigationProps } from "./Navigation.types";
import { ReactElement } from "react";

export const Hamburger: React.FC<HamburgerProps> = ({
  isOpen,
  setIsOpen,
  isHome = false,
}: HamburgerProps): ReactElement => {
  const [color, setColor] = useState<LogoColor>(LogoColor.Gold);

  const handleClickOpen = (): void => {
    setIsOpen((prevState: any) => !prevState);
  };

  useEffect((): any => {
    const c = isHome && !isOpen ? LogoColor.Light : LogoColor.Gold;
    setColor(c);

    return () => setColor(LogoColor.Gold);
  }, [isHome, isOpen]);

  return (
    <div className="o-navigation__hamburger">
      <div className="o-navigation__hamburger-bar">
        <Link title="Beauty Naturelle" activeCn="is-active" cn="o-navigation__logo" href={Routes.Home}>
          <p className="o-navigation__title">
            <Logo color={color} />
            <span className="u-visibility-hidden">Beauty Naturelle</span>
          </p>
        </Link>

        <UseAnimations
          className={cx(
            `o-navigation__toggle`,
            isOpen && `is-active`,
            isHome ? `o-navigation__toggle--light` : `o-navigation__toggle--default`
          )}
          reverse={isOpen}
          onClick={handleClickOpen}
          size={40}
          speed={2}
          wrapperStyle={{ marginTop: `5px` }}
          animation={menu2}
        />
      </div>
    </div>
  );
};

export const Navigation: React.FC<NavigationProps> = ({ isHome = false }: NavigationProps): ReactElement => {
  const { book } = useCompanyInfo();
  const { title } = useSiteMetaData();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [color, setColor] = useState<LogoColor>(LogoColor.Gold);

  useEffect((): void => {
    const c = isHome ? LogoColor.Light : LogoColor.Gold;
    setColor(c);
  }, [isHome]);

  return (
    <header className="u-wrapper">
      <h1 className="u-visibility-hidden">{title}</h1>
      <nav className={cx(`o-navigation`, isOpen && `is-open`)}>
        <Hamburger isHome={isHome} isOpen={isOpen} setIsOpen={setIsOpen} />
        <div className={cx(`o-navigation__list-wrapper`, isOpen && `active`)}>
          <ul className="o-navigation__list">
            <li className="o-navigation__item">
              <Link title="Beauty Naturelle" activeCn="is-active" cn="o-navigation__logo" href={Routes.Home}>
                <p className="o-navigation__title">
                  <Logo color={color} />
                  <span className="u-visibility-hidden">Beauty Naturelle</span>
                </p>
              </Link>
            </li>
            <li className="o-navigation__item">
              <Link
                title="Behandelingen"
                activeCn="is-active"
                cn="o-navigation__link u-heading-xsmall"
                href={Routes.Treatments}
              >
                Behandelingen
              </Link>
            </li>
            <li className="o-navigation__item">
              <Link
                title="Behandelingen"
                activeCn="is-active"
                cn="o-navigation__link u-heading-xsmall"
                href={Routes.Contact}
              >
                Contact
              </Link>
            </li>
            <li className="o-navigation__item">
              <Link
                title="Boek een afspraak"
                cn="o-navigation__button u-heading-xsmall"
                href={book}
                type={LinkType.External}
              >
                Boek een afspraak
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};
