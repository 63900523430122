import { filter, isNil, pathEq, pathOr } from "ramda";

import { CompanyInfoData } from "../../hooks/useCompanyInfo/useCompanyInfo.types";
import { ContactPageData } from "../../hooks/useContactPageData/useContactPageData.types";
import { IndexPageData } from "../../hooks/useIndexPageData/useIndexPageData.types";
import { TreatmentsPageData } from "../../hooks/useTreatmentsPageData/useTreatmentsPageData.types";

import { ContentfulLink } from "../../types/content-types";
import { Link } from "../../types/globals";
import { PageRoutes } from "../../const/globals";

import { DataType } from "./data.const";

export const getLink = (link: ContentfulLink): Link => {
  const { url, title, isInternal, page } = link;

  return {
    title,
    isInternal,
    href: !isNil(url) && !isInternal ? url : PageRoutes[page.slug],
  };
};

export const getByContentType = (data: any, type: DataType): any =>
  filter(pathEq([`sys`, `contentType`, `sys`, `id`], type), data);

export const formatIndexPageData = (pageData: any): IndexPageData => ({
  title: pageData.title,
  hero: getByContentType(pageData.content, DataType.Header)[0],
  blocks: getByContentType(pageData.content, DataType.Block),
});

export const formatContactPageData = (pageData: any): ContactPageData => ({
  title: pageData.title,
  blocks: getByContentType(pageData.content, DataType.Block),
});

export const formatTreatmentsPageData = (pageData: any): TreatmentsPageData => ({
  title: pageData.title,
  categories: pathOr([], [`content`], pageData),
});

export const formatCompanyInfo = (companyInfo: any): CompanyInfoData => {
  const facebook = pathOr(``, [`facebookUrl`], companyInfo);
  const instagram = pathOr(``, [`instagramUrl`], companyInfo);

  return {
    image: companyInfo.image,
    book: pathOr(``, [`appointmentUrl`], companyInfo),
    opening: pathOr([], [`openingTimes`, `days`], companyInfo),
    contact: {
      email: companyInfo.email,
      phone: companyInfo.phone,
      gsm: companyInfo.gsm,
      location: {
        street: companyInfo.location.street,
        number: companyInfo.location.number,
        bus: pathOr(``, [`location`, `bus`], companyInfo),
        zip: companyInfo.location.zip,
        city: companyInfo.location.city,
      },
    },
    socials: {
      facebook,
      instagram,
    },
  };
};
