import { useStaticQuery, graphql } from "gatsby";
import { formatCompanyInfo } from "../../helpers/data/data.helper";

import { CompanyInfoData } from "./useCompanyInfo.types";

export default (): CompanyInfoData => {
  const { contentfulBedrijfInformatie } = useStaticQuery(
    graphql`
      query CompanyInfo {
        contentfulBedrijfInformatie {
          title
          phone
          gsm
          email
          appointmentUrl
          facebookUrl
          instagramUrl
          location {
            city
            zip
            street
            number
            bus
          }
          openingTimes {
            days {
              day
              times
            }
          }
          image {
            title
            description
            gatsbyImageData(quality: 100, placeholder: BLURRED, formats: AUTO, aspectRatio: 0.87, layout: FULL_WIDTH)
          }
        }
      }
    `
  );

  return formatCompanyInfo(contentfulBedrijfInformatie);
};
