import { ReactNode } from "react";

export enum LinkType {
  Internal = ``,
  External = `_blank`,
}

export interface LinkProps {
  title: string;
  href: string;
  children: ReactNode;
  type?: LinkType;
  cn?: string;
  activeCn?: string;
}
