export enum Routes {
  Home = `/`,
  Treatments = `/behandelingen`,
  Contact = `/contact`,
}

export enum Pages {
  Home = `92023a67-6bf5-46c4-ab36-d8109a289139`,
  Contact = `7e848bf3-70b2-4b63-bc52-bbb488e50e08`,
  Treatments = `e6f7667b-a6ba-4ded-8df3-6d015b3870da`,
}
