import React from "react";
import { equals, pathOr } from "ramda";

import { Routes } from "../../types/routes";
import { Footer } from "../Footer/Footer";
import { Navigation } from "../Navigation/Navigation";

import { LayoutProps } from "./Layout.types";

const Layout: React.FC<LayoutProps> = ({ children, cn, location }: LayoutProps) => {
  const checkIsHome = (): boolean => {
    const path = pathOr(null, [`pathname`], location);

    return equals(path, Routes.Home);
  };

  return (
    <div className={cn}>
      <Navigation isHome={checkIsHome()} />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
